<template>
  <v-container fluid>
    <customer-details-info @redirect="redirectBack" />

    <v-card>
      <v-row class="mt-2">
        <v-col
          cols="12"
          class="pb-0"
        >
          <div class="">
            <!-- @change="tabChanged(tab)" -->
            <v-tabs
              v-model="tab"
              show-arrows
            >
              <span
                class="d-flex justify-space-between"
                style="flex: 1"
              >
                <span class="d-flex">
                  <v-tab
                    v-for="tabItem in items"
                    :key="tabItem.tab"
                  >
                    {{ tabItem.tab }}
                  </v-tab>
                </span>
                <div class="v-tab"><send-command /></div>
              </span>

              <v-tab-item
                v-if="isPermission('vehicle-telematics-detail.dashboard')"
              >
                <vehicle-telematics-chart />
              </v-tab-item>

              <!--Tab 1 : Message tab  -->
              <v-tab-item>
                <messages-tab />
              </v-tab-item>

              <!--Tab 2 : Command tab  -->
              <v-tab-item>
                <commands-tab />
              </v-tab-item>

              <!--Tab 2 : Stream tab  -->
              <v-tab-item>
                <streams-listing :show-headers="false" />
              </v-tab-item>

              <!--Tab 2 : Plugins tab  -->
              <v-tab-item>
                <plugins-listing :show-headers="false" />
              </v-tab-item>

              <!--Tab 2 : Plugins tab  -->
              <v-tab-item v-if="isPermission('vehicle-charge-sessions.list')">
                <charge-session-tab />
              </v-tab-item>
            </v-tabs>
            <!-- <div>
              <send-command />
            </div> -->
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
// import { debounce } from "lodash";

export default {
  components: {
    CustomerDetailsInfo: () =>
      import("@/components/sections/CustomerDetailsInfo.vue"),
    VehicleTelematicsChart: () => import("../tabs/VehicleTelematicsChart.vue"),
    MessagesTab: () => import("../tabs/MessagesTab1.vue"),
    CommandsTab: () => import("../tabs/CommandsTab.vue"),
    StreamsListing: () => import("@/views/modules/flespi/Streams.vue"),
    PluginsListing: () => import("@/views/modules/flespi/Plugins.vue"),
    ChargeSessionTab: () => import("../tabs/ChargeSessionTab.vue"),
    SendCommand: () => import("../utils/SendCommand.vue"),
  },

  data() {
    return {
      tab: 0,

      options: {
        // page: 1,
        // itemsPerPage: 50,
        // sortDesc: [false],
        // mustSort: false,
        // multiSort: false,
        // date: "today",
      },
    };
  },
  computed: {
    ...mapGetters({
      vehicleDetails: "vehicles/getVehicle",
      vehicleDetailsLoading: "vehicles/isLoadingVehicle",
    }),
    items() {
      let tab = [
        { tab: this.$t("messages"), content: "messages-tab" },
        { tab: this.$t("commands"), content: "commands-tab" },
        { tab: this.$t("streams"), content: "stream-tab" },
        { tab: this.$t("plugins"), content: "plugin-tab" },
      ];
      if (this.$admin.hasAccessTo("vehicle-telematics-detail.dashboard")) {
        tab.unshift({
          tab: this.$t("dashboard"),
          content: "dashboard",
        });
      }
      // if (this.$admin.hasAccessTo("vehicle-charge-sessions.list")) {
      //   tab.push({
      //     tab: this.$t("charge_session"),
      //     content: "charge-session-tab",
      //   });
      // }
      return tab;
    },
  },
  watch: {
    // options: {
    //   handler: debounce(async function () {
    //     const id = this.$route.params.imei;
    //     this.options.vehicleId = id;
    //     this.getTelematicDetails()
    //   }, 1000),
    //   deep: true,
    // },
  },

  async mounted() {
    const id = this.$route.params.imei;
    this.options.imei = id;

    // let imei = this.$route.params.imei;
    // await this.$store.dispatch("vehicles/show", imei);
    // this.getTelematicDetails()
  },

  methods: {
    isPermission(val) {
      return this.$admin.can(val);
    },
    // async getTelematicDetails() {
    //   await this.$store
    //     .dispatch("telematicDetails/list", this.options)
    //     .then(() => {
    //       if (this.options.page > (this.telematicDetailsMeta?.lastPage || 1)) {
    //         this.options.page = 1;
    //       }
    //     });
    // },
    async getCommandsListing() {
      await this.$store
        .dispatch("telematicDetails/commandslist", this.options)
        .then(() => {
          if (this.options.page > (this.telematicDetailsMeta?.lastPage || 1)) {
            this.options.page = 1;
          }
        });
    },

    // tabChanged(index) {
    //   // try {
    //   //   if (this.items[index].content == "messages-tab") {
    //   //     this.getTelematicDetails();
    //   //   } else if (this.items[index].content == "commands-tab") {
    //   //     this.getCommandsListing();
    //   //   }
    //   // } catch (error) {
    //   //   this.$store.dispatch("alerts/error", error.response.data.message);
    //   // }
    // },

    redirectBack() {
      this.$store.commit("telematicDetails/CLEAR_TELEMATICDETAILS");

      return this.$router.push({
        name: "vehicle_telematics",
        query: this.$route.query,
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.flex-boxes
  display: flex
  justify-content: space-around
  flex-wrap: wrap
  .box
    flex-basis: 15%
    margin: 0.5rem 0

    .v-card__title
      color: gray
      font-size: 1rem
      text-wrap : nowrap
    .v-card__text
      text-align : center

  .info-panel
    flex-basis: 25%
    margin: 0.5rem 0
    text-wrap: nowrap
    .v-card__text
      table
        width:100%
        font-size: 14px
      tr td:first-child
        color: gray
      tr td:last-child
        font-weight: 600

.collapse-btn
  display: flex
  justify-content: space-between
  align-items: center
</style>
